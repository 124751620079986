import BigNumber from 'bignumber.js';

const weightUnitLabel = {
  1: '吨',
  2: '千克',
  3: '克'
};
const unitConversion = {
  1: 1000 * 1000,
  2: 1000,
  3: 1
};

export const useConvertWeightSmallToLarge = (i, value) => {
  return {
    text: weightUnitLabel[i],
    value: value ? value / unitConversion[i] : 0
  };
};

export const useConvertWeightLargeToSmall = (i, value) => {
  let bigValue = new BigNumber(value);
  return {
    text: weightUnitLabel[i],
    value: value ? bigValue.multipliedBy(unitConversion[i]).toString() : 0
  };
};
