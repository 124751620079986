import BigNumber from 'bignumber.js';

export const volumeUnitLabel = {
  10: '方',
  20: '立方分米',
  30: '立方厘米'
};

const unitConversion = {
  10: 1000 * 1000,
  20: 1000,
  30: 1
};

export const useConvertVolumeSmallToLarge = (i, value) => {
  return {
    text: volumeUnitLabel[i],
    value: value ? value / unitConversion[20] : 0
  };
};
export const useConvertVolumeLargeToSmall = (i, value) => {
  let bigValue = new BigNumber(value);
  return {
    text: volumeUnitLabel[i],
    value: value ? bigValue.multipliedBy(unitConversion[20]).toString() : 0
  };
};
