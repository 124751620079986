import { apiMultFileTemporaryUrl } from '@api/common';

const useMultFileTemporaryUrl = async fileNameArray => {
  if (!fileNameArray?.length) return;

  const data = await apiMultFileTemporaryUrl({ filenames: fileNameArray });
  // console.log(data);

  let result = fileNameArray.map(item => {
    return data.data[item];
  });
  return result;
};
export default useMultFileTemporaryUrl;
