//settlementMethod
//0: 按装货： 装货重量*单价
//1： 按卸货：按卸货，卸货重量 *单价- （装-卸-允许亏损）*扣款单价 -角分抹零（装-卸-允许亏损）>=0,计价规则如上
//（装-卸-允许亏损）< 0，则，装货重量*单价-角分抹零
//2: 按装卸货的最小值： min[装货重量，卸货重量] * 单价

export const SettlementMethodType = {
  Load: 0,
  Min: 2,
  Unload: 1
};

export const LoadType = {
  Load: 0,
  Unload: 1
};

export const PriceUnitType = {
  Weight: 1,
  Volume: 2,
  Number: 3
};

export const BillPayType = {
  WHOLE_BILL: 10100,
  OIL_CARD: 20100,
  PREPAID: 20200,
  RECEIVED_PAID: 20300,
  RECEIPT_PAID: 20400,
  SERVICE_COST: 90100
};
