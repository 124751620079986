<template>
  <div class="flex flex-col source-goodsInfo-content bg-block">
    <!-- 导航栏 -->
    <NavBar :title="typeText + '确认'"> </NavBar>
    <div style="height: 100%; overflow-y: auto">
      <div class="overflow-y-auto content bg-block" style="width: 100%">
        <div class="flex items-center px-24 pt-16 pb-8">
          <!-- <tosb-icon name="icon-icon_tips" size="32" /> -->
          <Icon svgClass="img" name="icon_tips" />
          <span class="ml-4 text-muted text-14">请按实际情况填写{{ typeText }}信息</span>
        </div>

        <div class="bg-white">
          <div class="px-24 py-12 title font-18 text_secondary font-600 van-hairline--bottom">{{ typeText }}信息</div>

          <div class="mx-24 font-16">
            <!-- 时间 -->
            <div class="list van-hairline--bottom">
              <p>
                <span class="align-middle font-18 text-danger">*</span>
                <span class="text_muted font-16"> {{ typeText }}时间 </span>
              </p>
              <Field
                input-align="right"
                readonly
                v-model="selectDataTime"
                center
                type="text"
                placeholder="请选择时间"
                @click="selectTime"
              />
            </div>

            <!-- 重量 -->
            <div class="list van-hairline--bottom">
              <p>
                <span class="align-middle font-18 text-danger">*</span>
                <span class="text_muted font-16"> {{ typeText }}重量（{{ weightUnitText }}） </span>
              </p>
              <Field input-align="right" v-model="weight" center type="number" placeholder="请输入重量" />
            </div>
            <p class="text-right text-danger text-12" v-if="weightErr">{{ weightErr }}</p>

            <!-- 体积 -->
            <!-- <div class="list van-hairline--bottom" v-if="volume !== null && volume !== 0"> -->
            <div class="list van-hairline--bottom" v-if="volumeShow">
              <p>
                <span
                  class="align-middle font-18 text-danger"
                  v-if="goodsInfoDTO.priceUnitType === 2 || consignedCostRule.priceUnitType === 2"
                  >*</span
                >
                <span class="text_muted font-16">{{ typeText }}体积（方）</span>
              </p>
              <Field input-align="right" v-model="volume" type="number" placeholder="请输入体积" />
            </div>
            <p class="text-right text-danger text-12" v-if="volumeErr">{{ volumeErr }}</p>

            <!-- 件数 -->
            <!-- <div class="mt-24 list" v-if="number !== null && number !== 0"> -->
            <div class="list" v-if="numberShow">
              <p>
                <span
                  class="align-middle font-18 text-danger"
                  v-if="goodsInfoDTO.priceUnitType === 3 || consignedCostRule.priceUnitType === 3"
                  >*</span
                >
                <span class="text_muted font-16">{{ typeText }}件数（件）</span>
              </p>
              <Field input-align="right" v-model="number" type="number" placeholder="请输入件数" />
            </div>
          </div>
        </div>

        <div class="bg-white">
          <div class="flex items-center px-24 pt-16 pb-8 bg-block">
            <Icon svgClass="img" name="icon_tips" />
            <span class="ml-4 text-muted text-14">请根据平台要求上传{{ typeText }}磅单或{{ typeText }}照片</span>
          </div>

          <div class="py-12 pl-24 title font-18 text_main fw-500 van-hairline--bottom">
            上传照片 <span class="text-muted text-16">(最多9张)</span>
          </div>

          <div class="px-24 pt-16 img-box">
            <!-- 图片列表 -->
            <div class="preview-img" v-for="(item, index) in urlList" :key="index">
              <img @click.stop="handleImagePreview(index)" class="img" style="object-fit: cover" :src="item" />
              <Icon @click.native="handleDeleleImg(index)" svgClass="close-btn" name="a-icon_deletepicture"></Icon>
            </div>

            <!-- 上传按钮 -->
            <div class="upload" @click="handleUpload" v-if="urlList.length < 9">
              <div class="camera">
                <Icon svgClass="img" name="icon_add" />
              </div>
              <p class="text-primary">点击上传</p>
            </div>
          </div>
        </div>
      </div>

      <!--       v-if="contractList.length && !query.isEdit && query.type === '0'" -->
      <div class="flex px-24 py-12 bg-block font-14" v-if="contractList.length">
        <div>
          <Checkbox class="checkbox" icon-size="16" v-model="checked" shape="square"></Checkbox>
        </div>
        <div class="float-left">
          <span class="float-left">本人同意签署:</span>
          <!-- <a
            style="display: inline"
            @click="e => handleClickJump(e, item)"
            v-for="(item, index) in contractList"
            :key="index"
            >{{ item.contractName }}</a
          > -->
          <!-- hasTransportContract -->
          <span @click="handleClickJump" class="text-primary" v-if="hasTransportContract">
            {{ `《运输合作协议》《${contractName}》`
            }}<span v-if="showTrustAgreement">《委托代开增值税专用发票协议》</span>
          </span>
          <span @click="handleClickJump" class="text-primary" v-if="hasShareContract">《运力共享协议》</span>
        </div>
      </div>
    </div>
    <!-- 按钮栏 -->

    <div class="btn-box">
      <Button
        type="primary"
        size="large"
        :loading="loading"
        :disabled="
          !weight ||
            !checked ||
            !!weightErr ||
            !!volumeErr ||
            (!volume && (goodsInfoDTO.priceUnitType === 2 || consignedCostRule.priceUnitType === 2)) ||
            (!number && (goodsInfoDTO.priceUnitType === 3 || consignedCostRule.priceUnitType === 3))
        "
        @click="handleSubmit"
      >
        {{ typeText }}确认
      </Button>
    </div>
    <Popup v-model="slectDateVisible" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="datetime"
        title="选择完整时间"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @cancel="slectDateVisible = false"
        @confirm="confirmDateTime"
      />
    </Popup>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import NavBar from '@/components/NavBar/index';
import BigNumber from 'bignumber.js';
import { Button, Uploader, Toast, Field, Checkbox, ImagePreview, Popup, DatetimePicker } from 'vant';
import Icon from '@/components/Icon/index';
import { defineComponent, ref, onBeforeUnmount, onMounted, watch, del, computed } from '@vue/composition-api';
import { useRoute } from '@/utils/compsitionHack';
import useMultFileTemporaryUrl from '@hook/useMultFileTemporaryUrl';
import useGetDifferenceInfo from './hooks/useGetDifferenceInfo';
import { apiUnsignList, apiSignConstruct, apiNeedLoadPic } from '@api/mine';
import { useRouter } from '@/utils/compsitionHack';
import {
  getBillDetail,
  automaticFillApi,
  getTruckRelateApi,
  signShareContractAPi,
  getPlatformConfigApi
} from '@api/waybill';
import { useConvertWeightSmallToLarge } from '@hook/useConvertWeight';
import { useConvertVolumeSmallToLarge } from '@hook/useConvertVolume';
import ClientApp from '@/client/index';
import { LoadType } from './hooks/enum';
import { useUserStore } from '@/store/user';
import { PageEnum } from '@/enums/pageEnum';
import { PayTypeMap, PayTypeDescMap, WeightUnitDescMap } from '../constants';
import { weight2gram, gram2weight, volume2dm, dm2volume } from '@/utils/unit';
import { formatToDateTime } from '@wlhy-web-lib/shared';

import { useWaybillStore } from '@/store/waybill';

import { PricingRulesService, PaymentPlanService } from '@/costsService';
import { OilCardUnitTypes, SettlementMethodTypes } from '@/costsService/constants';

Toast.setDefaultOptions({ duration: 2000, forbidClick: true });

export default defineComponent({
  name: 'Source',
  components: { NavBar, Icon, Button, Uploader, Field, Checkbox, Popup, DatetimePicker },
  setup(_, ctx) {
    // 根据路由上的参数，判断该页面为「装货确认」 or 「卸货确认」
    const { query } = useRoute();
    const router = useRouter();
    const busy = ref(false);
    const loading = ref(false);

    const checked = ref(true);
    const contractId = ref(null);
    const companyId = ref(null);
    const isPerson = ref(false); // 承运人和司机是同一个人
    const contractList = ref([]); // 未签约合同列表

    // query.type - 0: 装货; 1: 卸货
    const { typeText, submitApiName } = useGetDifferenceInfo(+query.type);
    // 重量
    const weight = ref('');
    const weightErr = ref('');
    // 体积
    const volume = ref('');
    const volumeErr = ref('');
    const volumeShow = ref(false);
    // 件数
    const number = ref(null);
    const numberShow = ref(false);
    // 图片列表
    const urlList = ref([]);
    const fileNameList = ref([]);

    // 运单详情
    const goodsInfoDTO = ref({});

    const driverCarrierCostRuleVO = ref({});

    const userStore = useUserStore();

    const waybillStore = useWaybillStore();

    const companyLoadingUnloadingPictures = ref(2); // 装货图片是否必填

    // 总详情
    const info = ref({});

    // 货物规则
    const carrierCostRule = ref({});
    const consignedCostRule = ref({});
    const finalFloatPercent = ref(null);
    const finalLoadWeight = ref(null);
    const truckLoadWeight = ref(null);

    // 重量单位
    const weightUnit = computed(() => info.value.goodsInfoDTO?.weightUnit || info.value.companyWeightUnit);
    const weightUnitText = computed(() => WeightUnitDescMap[weightUnit.value]);

    const slectDateVisible = ref(false);
    const currentDate = new Date();
    const selectDataTime = ref(formatToDateTime(currentDate, 'yyyy/MM/dd hh:mm')); // 装卸货时间
    const minDate = ref(new Date()); // 开始时间 晚于接单时间/晚于装货时间
    const maxDate = ref(new Date()); // 结束时间 早于等于当前时间

    // 修复合同显示问题
    const hasTransportContract = ref(false);
    const hasShareContract = ref(false);

    const cyyPlatformConfigShow = ref(false);

    // 修正下取值 query.type - 0: 装货; 1: 卸货
    const composeFormData = function(waybillInfo = {}) {
      const goodsInfo = waybillInfo?.goodsInfoDTO || {};
      const weightUnit = goodsInfo.weightUnit || waybillInfo.companyWeightUnit;
      return {
        0: {
          weight: gram2weight(goodsInfo.loadingGoodsWeight || goodsInfo.weight, weightUnit),
          volume: dm2volume(goodsInfo.loadingGoodsVolume || goodsInfo.volume),
          number: goodsInfo.loadingGoodsNumber || goodsInfo.number
        },
        1: {
          weight: gram2weight(goodsInfo.unloadingGoodsWeight || goodsInfo.loadingGoodsWeight, weightUnit),
          volume: dm2volume(goodsInfo.unloadingGoodsVolume || goodsInfo.loadingGoodsVolume),
          number: goodsInfo.unloadingGoodsNumber || goodsInfo.loadingGoodsNumber
        }
      };
    };

    const queryAutomaticFillApi = async (e, isEdit) => {
      try {
        const { data } = await automaticFillApi({
          companyId: companyId.value
        });
        if (data === 0 && !(e.setDriverStatusOperationRecordVO?.createUserType !== 3) && !isEdit) {
          weight.value = '';
          volume.value = '';
          number.value = '';
        }
      } catch (error) {
        Toast.fail(error);
      }
    };

    const vehicleNumber = ref('');
    const driverName = ref('');
    const businessScopeType = ref(0);
    const needPic = ref(false);
    // 改变页面背景颜色
    onMounted(async () => {
      waybillStore.saveContractList([]);
      // 获取详情信息接口
      try {
        const res = await getBillDetail({ transportBillId: +query.id });
        const data = res.data;
        if (data) {
          fileNameList.value = +query.type === LoadType.Load ? data.loadingUrlList || [] : data.unloadingUrlList || [];
          info.value = data;
          carrierCostRule.value = data.shipperInfoDTO.driverCarrierCostRuleVO;
          consignedCostRule.value = data.shipperInfoDTO.shipperCostRuleDTO;
          // 装货图片是否必填 1 必填 2 非必填
          companyLoadingUnloadingPictures.value = data.companyLoadingUnloadingPictures;

          // ???
          isPerson.value = data.carrierInfoDTO.carrierIdCard === data.carrierInfoDTO.driverIdCard;

          contractId.value = data.contractId;

          companyId.value = data.companyId;

          goodsInfoDTO.value = data.goodsInfoDTO;

          driverName.value = data.carrierInfoDTO.driverName;
          vehicleNumber.value = data.carrierInfoDTO.carLicensePlate;
          businessScopeType.value = data.businessScopeType;

          driverCarrierCostRuleVO.value = data.shipperInfoDTO.driverCarrierCostRuleVO;
          if (+query.type === LoadType.Load) {
            minDate.value = new Date(data.transportBillTime);
            if (data.loadingDateTime) {
              selectDataTime.value = formatToDateTime(data.loadingDateTime || 0, 'yyyy/MM/dd hh:mm');
            }
          } else {
            minDate.value = new Date(data.loadingDateTime);
            if (data.unloadingDateTime) {
              selectDataTime.value = formatToDateTime(data.unloadingDateTime || 0, 'yyyy/MM/dd hh:mm');
            }
          }

          const formData = composeFormData(data)[query.type];
          if (formData) {
            volumeShow.value = formData.volume || formData.volume === 0 ? true : false;
            numberShow.value = formData.number || formData.number === 0 ? true : false;
            weight.value = formData.weight + '';
            volume.value = formData.volume + '';
            number.value = formData.number + '';
          }

          if (query.isEdit) {
            urlList.value =
              +query.type === LoadType.Load
                ? (await useMultFileTemporaryUrl(data.loadingUrlList)) || []
                : (await useMultFileTemporaryUrl(data.unloadingUrlList)) || [];
          }
          queryTruckInfoService();
          queryAutomaticFillApi(data, query.isEdit);
        }

        // 显示合同list 当运单没有contractId才会请求接口,司机等于承运人才会显示合同
        // 获取当前未签约列表
        // if (!contractId.value && isPerson.value) {
        apiUnsignList({
          companyId: data.companyId,
          driverId: userStore.driverId,
          type: 3 // 1-承运合同，2-共享协议，3-所有，不传默认1
        }).then(res => {
          let list = [];

          if (!contractId.value && isPerson.value) {
            list = res.data || [];
          } else {
            list = (res.data || []).filter(item => item.type === 2);
          }
          contractList.value = list;
          waybillStore.saveContractList(contractList.value);

          for (let i = 0; i < contractList.value.length; i++) {
            const item = contractList.value[i];
            if (item.contractName.includes('运输合作协议')) {
              hasTransportContract.value = true;
            }
            if (item.contractName.includes('运力共享协议')) {
              hasShareContract.value = true;
            }
          }
        });

        apiNeedLoadPic({
          companyId: data.companyId
        }).then(res => {
          needPic.value = res?.data === 1;
        });

        getPlatformConfigApi().then(res => {
          const val = res.data?.carrierSelfContractEntrustConfig;
          if (val) {
            try {
              const data = JSON.parse(val);
              if (data) {
                cyyPlatformConfigShow.value = data.enabled === 1;
              }
            } catch (e) {
              //
            }
          }
        });
        // }
      } catch (err) {
        Toast.fail(err);
      }
    });

    // 货重限制
    // 查询卡车最大装载量
    const queryTruckInfoService = async () => {
      try {
        const carrierInfo = info.value.carrierInfoDTO || {};
        let floatPercent = null;
        if (+query.type === LoadType.Unload) {
          floatPercent = info.value?.transportUnloadingWeightFloat || 0;
        } else {
          floatPercent = info.value?.transportWeightFloat || 0;
        }

        const { data: truckList } = await getTruckRelateApi({ carLicensePlate: carrierInfo.carLicensePlate });
        finalFloatPercent.value = (+floatPercent || 0) < 0 ? 0 : +floatPercent || 0;
        truckLoadWeight.value = truckList?.[0]?.loadWeight ? truckList?.[0]?.loadWeight : 0;
        finalLoadWeight.value = new BigNumber(finalFloatPercent.value)
          .plus(1000)
          .div(1000)
          .times(truckLoadWeight.value)
          .toNumber();
      } catch (error) {
        Toast.fail(error);
      }
    };

    const checkWeight = value => {
      let minLimit = weight2gram(+value, weightUnit.value);
      // const weightLimit = carrierCostRule.value.weightLimit || 0;
      if (value && (minLimit <= 1000 || minLimit >= 100000000)) {
        return '货重不合理，请保证1kg＜货重＜100吨';
      }
      if (finalLoadWeight.value && minLimit > finalLoadWeight.value) {
        if (finalLoadWeight.value === truckLoadWeight.value) {
          // return '货物总重量 > 核定载质量,请及时调整';
          return '已超载，请核实货重';
        }
        // return `货物总重量 > 核定载质量的${100 + gram2weight(finalFloatPercent.value)}%,请及时调整`;
        return '已超载，请核实货重';
      }
      return '';
    };
    // 保留三位小数
    watch(weight, () => {
      weight.value = weight.value?.match(/^\d*(\.?\d{0,3})/g)[0];
      weightErr.value = checkWeight(weight.value);
    });

    // 体积限制限制
    const checkVolume = value => {
      if (value && (+value <= 0 || +value >= 999)) {
        return '体积不合理，请保证0方＜体积＜999方';
      }
    };

    watch(volume, () => {
      volume.value = volume.value?.match(/^\d*(\.?\d{0,3})/g)[0];
      if (volumeShow.value) {
        volumeErr.value = checkVolume(volume.value);
      }
    });

    // 删除图片
    const handleDeleleImg = i => {
      del(urlList.value, i);
      del(fileNameList.value, i);
    };

    // 选择图片
    const handleUpload = () => {
      const isReviewOpen = userStore.showReviewKeys;
      const albumParams = { minNum: 1, maxNum: 9 - urlList.value.length, isCamera: true, type: 11 };
      if (isReviewOpen) {
        albumParams.action = 11; //0默认10前置11后置20相册
      }
      ClientApp.openAlbum(albumParams) //11加水印
        .then(data => {
          urlList.value = urlList.value.concat(data.map(item => item.url.replace(/\+/g, '%2B')));
          fileNameList.value = fileNameList.value.concat(data.map(item => item.fileName.replace(/\+/g, '%2B')));
        })
        .catch(err => {
          console.log(err);
        });
    };

    const handleClickJump = () => {
      // router.push({
      //   path: PageEnum.AGREEMENT,
      //   query: {
      //     companyId: companyId.value
      //   }
      // });
      //
      router.push({
        path: `/driver/waybill/contract?companyId=${companyId.value}&transportBillNumber=${
          info.value.transportBillNumber
        }&weight=${weight.value ? `${weight.value}${weightUnitText.value}` : ''}&volume=${
          volumeShow.value && volume.value ? `${volume.value}方` : ''
        }&number=${numberShow.value && number.value ? `${number.value}件` : ''}&configShow=${
          showTrustAgreement.value ? '1' : ''
        }`
      });
    };

    const composeGoodsInfo = function() {
      const goodsInfo = info.value.goodsInfoDTO || {};
      const params = {
        loadingGoodsNumber: 0,
        loadingGoodsVolume: 0,
        loadingGoodsWeight: 0,
        unloadingGoodsNumber: 0,
        unloadingGoodsVolume: 0,
        unloadingGoodsWeight: 0
      };
      if (+query.type === LoadType.Load) {
        params.loadingGoodsNumber = Number(number.value) || 0;
        params.loadingGoodsVolume = volume2dm(volume.value) || 0;
        params.loadingGoodsWeight = weight2gram(weight.value, weightUnit.value) || 0;

        params.unloadingGoodsNumber = goodsInfo.unloadingGoodsNumber || 0;
        params.unloadingGoodsVolume = goodsInfo.unloadingGoodsVolume || 0;
        params.unloadingGoodsWeight = goodsInfo.unloadingGoodsWeight || 0;
      }
      if (+query.type === LoadType.Unload) {
        params.loadingGoodsNumber = goodsInfo.loadingGoodsNumber || 0;
        params.loadingGoodsVolume = goodsInfo.loadingGoodsVolume || 0;
        params.loadingGoodsWeight = goodsInfo.loadingGoodsWeight || 0;

        params.unloadingGoodsNumber = Number(number.value) || 0;
        params.unloadingGoodsVolume = volume2dm(volume.value) || 0;
        params.unloadingGoodsWeight = weight2gram(weight.value, weightUnit.value) || 0;
      }
      return params;
    };

    // 根据计价规则算运费
    const calcCostsByRules = (rules, defaultCosts) => {
      const costsRules = { ...rules };
      const params = composeGoodsInfo();
      const options = {
        weightUnit: weightUnit.value, // 1: 吨  2: kg
        loadingWeight: params.loadingGoodsWeight,
        loadingVolume: params.loadingGoodsVolume,
        loadingNumber: params.loadingGoodsNumber,
        unloadingWeight: params.unloadingGoodsWeight,
        unloadingVolume: params.unloadingGoodsVolume,
        unloadingNumber: params.unloadingGoodsNumber
      };
      // 装货的时候按装货算，并且不能是编辑，运单状态小于等于 装货状态
      if (+query.type === LoadType.Load && info.value.transportBillStatus <= 40) {
        costsRules.settlementMethod = 0;
      }
      const { costs } = PricingRulesService.setRules(costsRules)
        .setOptions(options)
        .setCosts(defaultCosts)
        .setStatus(info.value.payInfoMap)
        .build();
      return costs;
    };

    // 支付计划
    const calcPaymentPlan = carrierTransportCost => {
      const {
        oilRatioNumber,
        oilRatioType,
        oilRatioPercentCalcRule,
        preRatioNumber,
        preRatioType,
        preRatioPercentCalcRule,
        informationRatioNumber,
        informationRatioType,
        informationSettlementMethod,
        oilCardPayment,
        arrivePayment,
        prePayment,
        receiptPayment,
        informationPayment,
        driverIdCard,
        carrierIdCard,
        payeeIdCard
      } = info.value.carrierInfoDTO;
      const showInfoPayment =
        !!driverIdCard && driverIdCard === payeeIdCard && (!carrierIdCard || carrierIdCard === driverIdCard);
      const oilCardRule = { oilRatioNumber, oilRatioType, oilRatioPercentCalcRule };
      const preRule = { preRatioNumber, preRatioType, preRatioPercentCalcRule };
      const infoRule = {
        informationRatioNumber,
        informationRatioType,
        visiable: showInfoPayment,
        informationSettlementMethod
      };
      const goodsInfo = info.value.goodsInfoDTO || {};
      const values = { oilCardPayment, arrivePayment, prePayment, receiptPayment, informationPayment };
      const calcInfo =
        informationRatioType === OilCardUnitTypes.Price &&
        !!informationRatioNumber &&
        ((+query.type === LoadType.Load && informationSettlementMethod !== SettlementMethodTypes.Unloading) ||
          (+query.type !== LoadType.Load && informationSettlementMethod !== SettlementMethodTypes.Loading));
      const result = PaymentPlanService.setRules({ oilCardRule, preRule, infoRule })
        .setStatus(info.value.payInfoMap)
        .setCosts(carrierTransportCost)
        .setValues(values)
        .setGoodsInfo({
          load:
            +query.type === LoadType.Load ? weight2gram(weight.value, weightUnit.value) : goodsInfo.loadingGoodsWeight,
          unload:
            +query.type === LoadType.Load
              ? goodsInfo.unloadingGoodsWeight
              : weight2gram(weight.value, weightUnit.value),
          calcInfo
        })
        .build();
      return result;
    };

    // ===============================================

    // 提交按钮
    const handleSubmit = async () => {
      if (loading.value) return;
      loading.value = true;

      // 如果是装货并且有共享运力合同，就需要先签掉
      if (+query.type === LoadType.Load) {
        const hasShareContract = contractList.value.some(item => item.type === 2);
        if (hasShareContract) {
          await signShareContractAPi({ driverId: userStore.driverId }).catch(e => {
            loading.value = false;
            throw e;
          });
        }
      }

      var statusOperateDateTime = new Date(selectDataTime.value).getTime() + 59999;
      let now = new Date().getTime();
      if (statusOperateDateTime > now) {
        statusOperateDateTime = now;
      }
      let params = {
        statusGoodsWeight: weight2gram(weight.value, weightUnit.value),
        statusOperateUrlList: fileNameList.value.length > 0 ? fileNameList.value : null,
        transportBillId: +query.id,
        statusOperateDateTime: statusOperateDateTime,
        source: 1 // 用于pc端地图上显示装卸货来源
      };

      if (needPic.value && !params.statusOperateUrlList) {
        Toast(`上传照片不能为空`);
        loading.value = false;
        return;
      }

      // 1.6.2 重构
      // 承运人运费
      if (!info.value.payDateTime) {
        params.carrierTransportCost = calcCostsByRules(
          info.value.shipperInfoDTO?.driverCarrierCostRuleVO,
          info.value.carrierInfoDTO.carrierTransportCost
        );
        const {
          oilCardPayment,
          prePayment,
          arrivePayment,
          receiptPayment,
          informationPayment,
          costs
        } = calcPaymentPlan(params.carrierTransportCost);
        params.carrierTransportCost = costs;
        params.oilCardPayment = oilCardPayment;
        params.prePayment = prePayment;
        params.arrivePayment = arrivePayment;
        params.receiptPayment = receiptPayment;
        params.informationPayment = informationPayment;
      }

      // 托运方运费计算
      if (info.value.chargeStatus == 1) {
        params.shipperTransportCost = calcCostsByRules(
          info.value.shipperInfoDTO?.shipperCostRuleDTO,
          info.value.shipperInfoDTO.shipperTransportCost
        );
      } else {
        params.shipperTransportCost = info.value.shipperInfoDTO.shipperTransportCost;
      }

      params.statusOperationId = query.oprateStatus;

      if (volume.value !== null) {
        params = {
          ...params,
          statusGoodsVolume: volume2dm(volume.value) || null
        };
      }
      if (number.value !== null) {
        params = { ...params, statusGoodsNumber: +number.value || null };
      }

      console.log(params);

      if (!busy.value) {
        busy.value = true;
        if (query.isEdit) {
          params.userLat = parseFloat(params.userLat) || null;
          params.userLng = parseFloat(params.userLng) || null;
          params.locationType = parseFloat(params.locationType) || null;
          query.userLat = parseFloat(query.userLat) || null;
          query.userLng = parseFloat(query.userLng) || null;
          query.locationType = parseFloat(query.locationType) || null;
          // 编辑状态不需要任何直接装货即可
          submitApiName({
            ...params,
            ...query
          })
            .then(() => {
              Toast(`${typeText}成功`);
              setTimeout(() => {
                loading.value = false;
                busy.value = false;
                ctx.root.$routerBack.call(ctx.root._router, true);
              }, 2000);
            })
            .catch(() => {
              busy.value = false;
              loading.value = false;
            });
        } else {
          // 非编辑状态
          ClientApp.getLocation()
            .then(response => {
              // 电子围栏
              // fix 用户不授权的时候经纬度传入 null 路由客户端每个url会加上经纬度
              params.userLat = parseFloat(response.userLat) || null;
              params.userLng = parseFloat(response.userLng) || null;
              params.locationType = parseFloat(response.locationType) || null;
              params.adCode = response.adCode || '';
              params.address = response.address || '';
              query.userLat = parseFloat(response.userLat) || null;
              query.userLng = parseFloat(response.userLng) || null;
              query.locationType = parseFloat(response.locationType) || null;

              if (userStore.showReviewKeys && hasTransportContract.value) {
                const item = contractList.value.find(v => v.contractName.includes('运输合作协议'));
                if (item) {
                  waybillStore.saveLoadParams({
                    ...params,
                    ...query
                  });
                  router.push({
                    path: PageEnum.AGREEMENT,
                    query: {
                      companyId: info.value.companyId,
                      driverId: userStore.driverId,
                      taxSourceId: item.taxSourceId,
                      type: item.type,
                      transportBillNumber: info.value.transportBillNumber,
                      showSign: 1,
                      loadType: query.type,
                      weight: weight.value ? `${weight.value}${weightUnitText.value}` : '',
                      volume: volumeShow.value && volume.value ? `${volume.value}方` : '',
                      number: numberShow.value && number.value ? `${number.value}件` : ''
                    }
                  });
                }
              } else {
                submitApiName({
                  ...params,
                  ...query
                })
                  .then(() => {
                    Toast(`${typeText}成功`);
                    setTimeout(() => {
                      busy.value = false;
                      loading.value = false;
                      ctx.root.$routerBack.call(ctx.root._router, true);
                    }, 2000);
                    syncLocation(params);
                  })
                  .catch(() => {
                    busy.value = false;
                    loading.value = false;
                  });
              }
            })
            .catch(err => {
              busy.value = false;
              loading.value = false;
            });
        }
      }
    };

    async function syncLocation(location) {
      if (businessScopeType.value == 30) {
        Toast(`${typeText}成功`);
        setTimeout(() => {
          busy.value = false;
          loading.value = false;
          ctx.root.$routerBack.call(ctx.root._router, true);
        }, 2000);
        return null;
      }
      return ClientApp[query.type == LoadType.Load ? 'startShippingInfo' : 'stopShippingInfo']({
        shippingList: [
          {
            shippingNoteNumber: info.value.transportBillNumber,
            serialNumber: info.value.serialNumber || '0000',
            startCountrySubdivisionCode:
              info.value.addressInfoDTO.senderDistrict ||
              info.value.addressInfoDTO.senderCity ||
              info.value.addressInfoDTO.senderProvince,
            startLocationText: info.value.addressInfoDTO.senderLocation,
            endCountrySubdivisionCode:
              info.value.addressInfoDTO.receiverDistrict ||
              info.value.addressInfoDTO.receiverCity ||
              info.value.addressInfoDTO.reveiverProvince,
            endLocationText: info.value.addressInfoDTO.receiverLocation
          }
        ],
        companyId: info.value.companyId,
        taxSourceId: info.value.taxSourceId,
        userLat: location.userLat,
        userLng: location.userLng,
        locationType: location.locationType,
        adCode: location.adCode,
        address: location.address,
        vehicleNumber: vehicleNumber.value,
        driverName: driverName.value,

        businessScene: info.value.businessScene || 0,
        shipperTransportBillNumber: info.value.shipperTransportBillNumber || ''
      });
    }

    // 图片预览
    function handleImagePreview(index) {
      const list = urlList.value.map(url => url.replace(/\+/g, '%2B'));
      ImagePreview({
        images: list,
        startPosition: index || 0,
        overlayStyle: {
          backgroundColor: '#000'
        }
      });
    }

    function selectTime() {
      maxDate.value = new Date();
      slectDateVisible.value = true;
    }

    function formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      if (type === 'hour') {
        return val + '时';
      }
      if (type === 'minute') {
        return val + '分';
      }
      return val;
    }

    function confirmDateTime(val) {
      let selectDate = new Date(val);
      if (selectDate < minDate.value) {
        selectDate = minDate.value;
      }
      if (selectDate > maxDate.value) {
        selectDate = maxDate.value;
      }
      slectDateVisible.value = false;
      selectDataTime.value = formatToDateTime(selectDate, 'yyyy/MM/dd hh:mm');
    }

    const contractName = computed(() => {
      return info.value.businessScopeType === 30 ? '委托代开增值税专用发票协议' : '税务委托代理委托书';
    });

    const showTrustAgreement = computed(() => {
      return info.value.businessScopeType !== 30 && cyyPlatformConfigShow.value;
    });

    // 卸载组件前
    onBeforeUnmount(() => {
      document.querySelector('body').removeAttribute('style');
    });

    return {
      typeText,
      number,
      numberShow,
      info,
      weight,
      weightErr,
      volume,
      volumeErr,
      volumeShow,
      checked,
      contractId,
      contractList,
      urlList,
      busy,
      query,
      handleSubmit,
      isPerson,
      loading,
      goodsInfoDTO,
      consignedCostRule,
      handleUpload,
      companyLoadingUnloadingPictures,
      useConvertWeightSmallToLarge,
      useConvertVolumeSmallToLarge,
      handleDeleleImg,
      handleClickJump,
      handleImagePreview,
      handleInputFocus: e => {
        e.target.select();
      },

      weightUnitText,
      slectDateVisible,
      selectDataTime,
      selectTime,
      currentDate,
      minDate,
      maxDate,
      formatter,
      confirmDateTime,
      formatToDateTime,

      hasTransportContract,
      hasShareContract,

      contractName,
      showTrustAgreement
    };
  }
});
</script>
<style lang="less" scoped>
@import '../styles/goodsInfo.less';
</style>
<style lang="less">
.source-goodsInfo-content {
  height: 100vh;
  .preview-img {
    overflow: visible;
  }
  .close-btn {
    position: absolute;
    top: -0.06rem;
    right: -0.06rem;
    background-color: #0076ff;
    border-radius: 50%;
    width: 0.16rem !important;
    height: 0.16rem !important;
  }
  .customer-pdf {
    position: fixed;
    bottom: 0.72rem;
    height: 0.62rem;
    width: 100vw;
    background-color: #fff;
  }
  .van-cell {
    flex: 1;
    padding: 0;
    line-height: 0.4rem;
  }

  .van-field__control {
    @apply text-16;
  }
  .van-field__control::placeholder {
    @apply text-placeholder;
    @apply text-16;
  }

  .checkbox {
    border-radius: 0.02rem;
    margin-right: 0.08rem;
    margin-top: 0.02rem;
  }
}
</style>
